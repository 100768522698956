<template>
  <main-frame>
    <div class="main">
      <a-spin :spinning="loading && !inWholeWhiteList" class="app-spinning" />
      <div class="appContainer" v-html="content" />
    </div>
  </main-frame>
</template>
<script>
import mainFrame from '@/components/mainFrame/index.vue'
import { mapState } from 'vuex'

export default {
  props: ['loading', 'content'],
  components: {
    'main-frame': mainFrame,
  },
  computed: {
    ...mapState(['inWholeWhiteList']),
  },
}
</script>
<style lang="scss" scoped>
.main {
  .app-spinning {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
  }
  .appContainer {
    padding: 20px;
  }
}
</style>
